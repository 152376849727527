import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export const handleDowoad = (base64String, filename) => {
    // Convert base64 to binary
    const bytes = atob(base64String);
    const arrayBuffer = new ArrayBuffer(bytes.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < bytes.length; i++) {
        uint8Array[i] = bytes.charCodeAt(i);
    }

    // Read the binary data using XLSX
    const workbook = XLSX.read(uint8Array, { type: 'array' });

    // Save the workbook as Excel file
    const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Convert array buffer to Blob
    const blob = new Blob([wbout], { type: 'application/octet-stream' });

    // Save the Blob as a file using FileSaver.js
    saveAs(blob, filename);
};

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const dowoadAsCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
}
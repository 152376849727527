import React, { useState, useEffect, useRef } from 'react'
import { Skeleton, Spin } from 'antd';

import FormZ from '../components/FormZ'

import { sendNotify } from '../helper/notification';

import UploadRep from '../repository/uploadRep';

export default function Upload() {
  const formRef = useRef();
  const uploader = useRef();

  const [country, setCountry] = useState([]);
  const [docType, setDocType] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);
  const [documents, setDocuments] = useState();
  const [loadingObj, setLoadingObj] = useState({});
  const [errorObj, setErrorObj] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);


  useEffect(() => {
  }, []);

  let formSchema = [
    {
      name: 'category',
      value: '',
      type: 'text',
      label: 'Category',
      placeholder: 'Enter document Category',
      required: true,
    },
    {
      name: 'name',
      value: '',
      type: 'text',
      label: 'Name',
      placeholder: 'Enter document Name',
      required: true,
    },
  ]

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const onFileUpload = (e, type) => {
    let doc = [documents]
    let loading = { ...loadingObj }
    let error = { ...errorObj }
    let obj = {}
    let file = e.target.files[0]
    let fileUrl = file ? URL.createObjectURL(file) : null
    // Check file type
    if (file) {
      const allowedFileTypes = ['image/png', 'image/jpeg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf'];
      if (!allowedFileTypes.includes(file.type)) {
        sendNotify("error", "Invalid file type! Please upload PNG, JPEG, XLSX, or PDF files.");
        setDocuments();
        return;
      }
    }

    obj['name'] = file?.name

    loading[type] = true
    setLoadingObj(loading)

    if (!file) {
      error[type] = true
      setErrorObj(error)
      sendNotify("error", "Please Select file")
      setDocuments()
    } else {
      error[type] = false
      loading[type] = false
      setErrorObj({})
      setLoadingObj(loading)
      getBase64(file).then(
        data => {
          obj['url'] = fileUrl;
          obj['base64'] = data;
          doc.push(obj)
          var count = doc.length
          setDocuments(doc[count - 1])
        }
      );
    }
  }

  const onSubmitDoc = () => {
    setSubmitLoading(true)
    let valid = formRef.current.validFrom()
    console.log(valid, documents, "payloadpayload")

    if (valid && documents) {
      const base64Data = documents && documents?.base64.split(',')[1];
      let payload = formRef.current.getPayload();
      payload['file'] = base64Data
      payload['document'] = "upload"
      console.log(payload, "payloadpayload")
      UploadRep.uploadDoc(payload).then(response => {
        if (response) {
          setSubmitLoading(false)
          if (response?.error) {
            sendNotify('error', response.message)
          } else {
            formRef.current.clearForm()
            setDocuments()
            sendNotify('success', response.message)
          }
        }
      }).catch(error => {
        sendNotify('error', error)
      })
    } else {
      let error = { ...errorObj }
      setSubmitLoading(false)
      console.log(documents, "documents")

      if (!valid) {
        sendNotify('error', 'Oops! It looks like some fields are missing. Please fill out all the required fields to proceed!')
      }
      else if (!documents) {
        error['front'] = true
        sendNotify('error', 'Oops! Document Missing')
      }
      setErrorObj(error)
    }
  }

  const getDocType = (code) => {
    console.log(code, 'getDocType');
    let arr = []
    let obj = {}
    UploadRep.getDocOptionsByCountry(code).then(response => {
      if (response) {
        setCountryLoading(false)
        if (response?.error) {
          sendNotify('error', response.message)
          setDocType([])
        } else {
          let data = response?.data?.documents
          data?.length > 0 && data.map((option) => {
            obj['label'] = `${option}`
            obj['value'] = option
            arr.push(obj)
            obj = {}
          })
          setDocType(arr)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
    })
  }

  return (
    <div>
      <div className='container-fluid px-0'>
        <div className="page-card">
          <h2>Document Upload</h2>
          <div className="row mt-3 mt-md-4">
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <div className='w-100'>
                <Spin spinning={loadingObj['front'] == true ? true : false}>
                  <div className={`custom-dragger p-0 w-100 h-250 ${errorObj['front'] ? 'upload-err' : ''}`}>
                    <div className='custom-btn flex-column' onClick={() => uploader.current.click()}>
                      <input type="file" accept=".png, .jpeg, .jpg, .xlsx, .pdf" name="" id="" ref={uploader} onChange={onFileUpload} className='d-none' required />
                      <i className={`fal ${documents ? 'icon-green fa-check-circle' : 'fa-file-upload'}`}></i>
                      <h6>{documents?.name ?? 'Click to upload file'}</h6>
                      <p>Allowed file formats (PNG,JPEG,XLSX,PDF) </p>
                    </div>
                  </div>
                </Spin>
              </div>
            </div>
            <div className="col-md-5 mb-4 mb-md-0">
              <Spin spinning={countryLoading}>
                <h4 className='mb-4'>Additional Information</h4>
                <FormZ formSchema={formSchema} ref={formRef} formOptions={{ country: country, document_type: docType }} updateOptions={(code) => getDocType(code)} />
                <div className="d-flex justify-content-center">
                  <button className='secondary-btn me-3' onClick={() => formRef.current.clearForm()}>Clear</button>
                  <button className={`primary-btn ${submitLoading ? 'btn-loading' : ''}`} onClick={onSubmitDoc}>
                    <i className={`far ${submitLoading ? 'fa-spinner-third' : 'fa-check-circle'}`}></i>
                    &nbsp; Submit</button>
                </div>
              </Spin>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

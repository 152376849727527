import axios from "axios";
class ReportRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getAllReports() {
        let url = `/getChartData`;
        const response = await axios({method: 'get', url})
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getMonthTrend() {
        let url = `/monthtrend`;
        const response = await axios({method: 'get', url})
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }

}

export default new ReportRepository();
import React, { useEffect, useState, useRef } from 'react'
import { Table, Tag, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import moment, { fn } from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogged } from '../store/auth/authSlice';


import { sendNotify } from '../helper/notification'
import authRep from '../repository/authRep'
import FormZ from '../components/FormZ';

const { confirm } = Modal;

export default function Accounts() {
  const dispatch = useDispatch()
  const formRef = useRef();
  const passRef = useRef();

  let details = useSelector((state) => state.auth.userDetails)

  const navigate = useNavigate();

  const [id, setId] = useState()
  const [mode, setMode] = useState('Add')
  const [trackLoading, setTrackLoading] = useState(false)
  const [trackList, setTrackList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({})

  useEffect(() => {
    getAccounts()
  }, []);

  let formSchema = [

    {
      name: 'user_name',
      value: '',
      type: 'text',
      label: 'Username',
      placeholder: 'Enter username',
      required: true,
    },
    {
      name: 'email',
      value: '',
      type: 'text',
      label: 'Email',
      placeholder: 'Enter email',
      required: true,
    },
    {
      name: 'password',
      value: '',
      type: 'password',
      label: 'Password',
      placeholder: 'Enter Password',
      required: true,
    },
    {
      name: 'role',
      value: '',
      type: 'select',
      label: 'Role',
      placeholder: 'Enter role',
      options: [
        {
          value: 'admin',
          label: 'Admin'
        },
        {
          value: 'user',
          label: 'User'
        }
      ],
      required: true,
    },
  ]

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'key',
    },
    {
      title: 'Username',
      dataIndex: 'user_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (_, { role }) => (
        <>
          {role === "admin"
            ? <Tag color={"gold"} key={role}>{role}</Tag> :
            role === "analyst" ?
              <Tag color={"purple"} key={role}>{role}</Tag> :
              <Tag color={"green"} key={role}>{role}</Tag>
          }
        </>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions'
    },
  ];

  const getAccounts = () => {
    setTrackLoading(true)
    authRep.allAccounts().then(response => {
      setTrackLoading(false)
      if (response) {
        if (response?.error) {
          sendNotify('error', response.message)
          setTrackList([])
        } else {
          let data = response?.data?.map((data, i) => (
            {
              key: i + 1,
              user_name: data?.user_name,
              email: data?.email,
              role: data?.role,
              actions: <div className="d-flex">
                <button className="primary-btn action-btn me-3" title='Edit User' onClick={() => openModal(data, "Edit")}><i className="fas fa-edit"></i></button>
                <button className="primary-btn action-btn btn-red me-3" title='Delete User' onClick={() => confirmDelete(data?._id, data?.email)}><i className="fas fa-trash-alt"></i></button>
              </div>
            }
          ))
          setTrackList(data)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
      setTrackLoading(false)
    })
  }

  const openModal = (data, mode) => {
    setMode(mode)
    setFormData(data)
    setIsModalOpen(true)
  }

  const confirmDelete = (id, email) => {
    confirm({
      title: <h5>Confirm Delete</h5>,
      content: <p>Are you sure you want to delete this <span>{email}</span> account?</p>,
      className: 'custom-confirm',
      centered: true,
      type: 'warning',
      okText: 'Delete',
      okButtonProps: { className: 'btn-red' },
      onOk() {
        deleteUser(id);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const deleteUser = (id) => {
    authRep.deleteUser(id).then(response => {
      if (response) {
        if (response?.error) {
          sendNotify('error', response.message)
        } else {
          sendNotify('success', response.message)
          getAccounts()
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
    })
  }

  const handleOk = () => {
    let valid = formRef.current.validFrom()
    if (valid) {
      let formValues = formRef.current.getPayload();
      // const cleanedData = removeNullProperties(formValues);
      // cleanedData.id = id.actions
      if (mode === 'Edit') {
        formValues.id = formData._id;
        delete formValues.password
      }

      authRep[mode === 'Add' ? 'signup' : 'updateUser'](formValues).then(response => {
        setTrackLoading(false)
        if (response) {
          if (response?.error) {
            sendNotify('error', response.error.response.data.message)
          } else {
            getAccounts();
            if (mode === 'Edit') {
              if (details.userId === response?.data?._id) {
                localStorage.setItem("username", response?.data?.user_name)
              }
            }
            dispatch(checkLogged())
            setIsModalOpen(false);
            formRef.current.clearForm()
            sendNotify('success', response.message)
          }
        }
      }).catch(error => {
        sendNotify('error', error)
      })
    }
  };

  const handleCancel = () => {
    formRef.current.clearForm()
    setIsModalOpen(false);
  };


  const filteredFormSchema = mode === 'Edit' ? formSchema.filter(field => field.name !== 'password') : formSchema;


  return (
    <section className='mt-md-3'>
      <div className="page-header flex-md-row flex-column">
        <h1>Account</h1>
        <button className="primary-btn" key={id} onClick={() => openModal(null, "Add")}><i className="fas fa-plus"></i> Add User</button>

      </div>

      <Table columns={columns} dataSource={trackList} loading={trackLoading} pagination={{ className: 'custom-pagination' }} className='custom-table' />

      <Modal title={`${mode} account`} className='custom-modal' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <FormZ formSchema={filteredFormSchema} ref={formRef} formData={formData} />
      </Modal>

    </section>
  )
}
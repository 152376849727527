import axios from "axios";

class UploadRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async uploadDoc(payload) {
        let url = `/track/updateworldcheckreport`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }

    async getDocOptionsByCountry(code) {
        let url = `countries/getdocuments?code=${code}`;
        const response = await axios({ method: 'get', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new UploadRepository();
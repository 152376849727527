import React, { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Tag, Select, Spin } from 'antd';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import 'jspdf-autotable'
import { useSelector } from 'react-redux';
import moment from 'moment';

import { sendNotify } from '../helper/notification';

import logoWhite from '../assets/img/logo-pdf.png'
import background from '../assets/img/cvs-logo.png'
import WorldIcon from '../assets/img/world-icon.png'
import TrackRep from '../repository/TrackRep';
import CountryRep from '../repository/countryRep';

import { getCountyName } from '../helper/download'

export default function WorldCheck() {
  const { id } = useParams();
  const navigate = useNavigate();
  const uploader = useRef(null)
  const [wcFile, setWcFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [status, setStatus] = useState(null)
  const [statusLoading, setStatusLoading] = useState(false)
  const [wcData, setWcData] = useState({})
  const [finalLoading, setFinalLoading] = useState(false)

  let details = useSelector((state) => state.auth.userDetails)

  const backToList = () => {
    navigate(`/app/fraud-assessment/${id}`)
  }

  useEffect(() => {
    getWcData(id)
  }, [])

  const [time, setTime] = useState({
    sec: 0,
    min: 0,
    hr: 0
  });
  const [intervalId, setIntervalId] = useState();

  const updateTimer = () => {
    setTime((prev) => {
      let newTime = { ...prev };
      // update sec and see if we need to increase min
      if (newTime.sec < 59) newTime.sec += 1;
      else {
        newTime.min += 1;
        newTime.sec = 0;
      }
      // min has increased in *newTime* by now if it was updated, see if it has crossed 59
      if (newTime.min === 60) {
        newTime.min = 0;
        newTime.hr += 1;
      }

      return newTime;
    });
  };

  const pauseOrResume = () => {
    if (!intervalId) {
      let id = setInterval(updateTimer, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId("");
    }
  };

  const getWcData = (id) => {
    setLoading(true)
    TrackRep.getWcData(id).then(async response => {
      setLoading(false)
      let data = response.data
      if (response) {
        if (response?.error) {
          sendNotify('error', response.message)
        } else {
          setStatus(response?.data?.world_check)
          // console.log(response?.data);
          if (response?.data?.nationality) {
            let nationalityName = await CountryRep.getStateByCountry(response?.data?.nationality).then(country => {
              if (country) {
                if (country?.error) {
                  sendNotify('error', country.message)
                } else {
                  return country?.data?.name
                }
              }
            }).catch(error => {
              sendNotify('error', 'Failed to fetch Data')
            })

            data['nationality'] = nationalityName
          }
          if (response?.data?.issue_country) {
            let issueCountryName = await CountryRep.getStateByCountry(response?.data?.issue_country).then(country => {
              if (country) {
                if (country?.error) {
                  sendNotify('error', country.message)
                } else {
                  return country?.data?.name
                }
              }
            }).catch(error => {
              sendNotify('error', 'Failed to fetch Data')
            })
            data['issue_country'] = issueCountryName
          }
          setWcData(data)
          setTime(response?.data?.world_check_time ?? {
            sec: 0,
            min: 0,
            hr: 0
          })
          if (response?.data?.status != "APPROVED") {
            pauseOrResume()
          }
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
      setLoading(false)
    })
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  }

  const downloadFile = (fileSrc, fileName) => {
    var a = document.createElement("a");
    a.href = fileSrc;
    a.download = fileName;
    a.click();
  }

  const onFileUpload = (e) => {
    let file = e.target.files[0]
    console.log(file);
    if (file?.size > 10485760) {
      sendNotify("error", "File is too big!, Upload below 5MB file.")
    } else {
      if (file?.type === 'application/pdf') {
        setUploading(true)
        setWcFile(file)
        let payload = {
          trackId: id,
          document: "upload",
          file: ''
        }
        getBase64(file).then(
          data => {
            payload['file'] = data;
            TrackRep.uploadWcReport(payload).then(response => {
              if (response) {
                setUploading(false)
                if (response?.error) {
                  sendNotify('error', response.message)
                } else {
                  setWcFile(file)
                  getWcData(id)
                }
              }
            }).catch(error => {
              sendNotify('error', 'Failed to fetch Data')
              setUploading(false)
            })
          }
        );
      } else {
        sendNotify("error", "Only PDF file are supported.")
      }
    }
  }

  const savePDF = () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
    });

    doc.setDrawColor(5, 28, 36);
    doc.setLineWidth(50);
    doc.line(0, 25, 240, 25);
    doc.setDrawColor(26, 149, 249);
    doc.setLineWidth(2);
    doc.line(60, 28, 220, 28);
    doc.addImage(logoWhite, "PNG", 4, 7, 50, 25);
    doc.setTextColor(255, 255, 255);
    doc.setFont(undefined, 'bold').setFontSize(22).text("Customer Verification Report", 93, 43);
    doc.addImage(background, "PNG", -100, 110, 180, 100);
    const data1 = [
      ['Facial Biometric', wcData?.face_match ? 'Match' : 'Not Matched'],
      ['Sub Results', wcData?.sub_results],
      ['World Check Result', status ? "Hit" : "Not Hit"],
    ];
    const head1 = [['Document Summary', '']];
    const data2 = [
      ['First Name', wcData?.first_name ?? "No Data"],
      ['Last Name', wcData?.last_name ?? "No Data"],
      ['DOB', wcData?.dob ? moment(wcData?.dob).format("DD-MM-YYYY") : "No Data"],
      ['Gender', wcData?.gender ?? "No Data"],
      ['Nationality', wcData?.nationality ?? "No Data"],
    ];
    const head2 = [['Customer Information', '']];
    const data3 = [
      ['Document Type', wcData?.document_type ?? "No Data"],
      ['Issuing Country', wcData?.issue_country ?? "No Data"],
      ['State/Region', wcData?.region ?? "No Data"],
      ['Date of Issue', wcData?.issued_date ? moment(wcData?.issued_date).format("DD-MM-YYYY") : "No Data"],
      ['Date of Expiry', wcData?.expired_date ? moment(wcData?.expired_date).format("DD-MM-YYYY") : "No Data"],
      ['Document No', wcData?.card_id ?? "No Data"],
      // ...wcData?.dynamicFields?.map((data) => [data?.label, data?.value])
    ];
    if (wcData?.dynamicFields?.length > 0) {
      wcData?.dynamicFields?.map(data => {
        data3.push([data?.label, data?.value])
      })
    }
    const head3 = [['Document Information', '']];
    var finalY = doc.lastAutoTable.finalY || 40
    doc.autoTable({
      head: head1,
      body: data1,
      theme: 'grid',
      startY: finalY + 15, // Adjust the vertical position as needed
      styles: {
        fontSize: 12,
        color: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      bodyStyles: {
        minCellHeight: 10,
        valign: 'middle',
        textColor: [0, 0, 0],
      },
      headStyles: {
        minCellHeight: 12,
        fillColor: [0, 95, 204],
        valign: 'middle',
        textColor: [255, 255, 255],
      },
    });
    doc.autoTable({
      head: head2,
      body: data2,
      theme: 'grid',
      startY: doc.autoTable.previous.finalY + 8, // Start below the first table
      styles: {
        fontSize: 12,
        color: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      bodyStyles: {
        minCellHeight: 10,
        valign: 'middle',
        textColor: [0, 0, 0],
      },
      headStyles: {
        minCellHeight: 12,
        fillColor: [0, 95, 204],
        valign: 'middle',
        textColor: [255, 255, 255],
      },
    });
    doc.autoTable({
      head: head3,
      body: data3,
      theme: 'grid',
      startY: doc.autoTable.previous.finalY + 8, // Start below the first table
      styles: {
        fontSize: 12,
        color: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.5,
      },
      bodyStyles: {
        minCellHeight: 10,
        valign: 'middle',
        textColor: [0, 0, 0],
      },
      headStyles: {
        minCellHeight: 12,
        fillColor: [0, 95, 204],
        valign: 'middle',
        textColor: [255, 255, 255],
      },
    });
    doc.setDrawColor(0, 0, 0);
    doc.setLineWidth(0.5);
    doc.line(0, 270, 250, 270);
    doc.autoTable({
      html: '#table1',
      columnStyles: {
        0: { cellWidth: 100 },
        1: { cellWidth: 80 },
      }
    })
    doc.setTextColor(0, 0, 0);
    doc.addImage(WorldIcon, "PNG", 157, 279.5, 5, 5);
    doc.setFont(undefined, 'light').setFontSize(12).text("www.northlark.com", 163, 283);
    doc.save(`wc-${id}.pdf`);
  }

  const handleChange = (value) => {
    setStatusLoading(true)
    setStatus(value)
    let payload = {
      trackId: id,
      world_check: value
    }

    TrackRep.updateWorldCheckStatus(payload).then(response => {
      if (response) {
        setStatusLoading(false)
        if (response?.error) {
          sendNotify('error', response.message)
        } else {
          sendNotify('success', response.message)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
      setStatusLoading(false)
    })
  };

  const updateFinalStatus = () => {
    setFinalLoading(true)
    let payload = {
      trackId: id,
      world_check_time: time
    }
    TrackRep.updateFinalStatus(payload).then(response => {
      if (response) {
        setFinalLoading(false)
        if (response?.error) {
          sendNotify('error', response.message)
        } else {
          sendNotify('success', response.message)
          if (details?.role === 'admin') {
            navigate(`/app/tracks`)
          } else {
            navigate(`/app/track`)
          }
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
      setFinalLoading(false)
    })
  }

  return (
    <section>
      <div className="page-header flex-md-row flex-column">
        <div className="d-flex align-items-center">
          <button className='primary-btn' onClick={backToList}><i className="far fa-backward"></i> Back</button>
          <h1 className='ms-2'>World Check</h1>
        </div>
        <div className="step-wrapper">
          <button>1</button>
          <button>2</button>
          <button className='active'>3</button>
        </div>
        <div>
          <p className='task-timer'>{`${time.hr < 10 ? 0 : ""}${time.hr}:${time.min < 10 ? 0 : ""}${time.min}:${time.sec < 10 ? 0 : ""}${time.sec}`}</p>
        </div>
      </div>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-md-6">
            <Spin spinning={loading}>
              <div className="wc-card">
                <div className="pdf-header">
                  <div className="header-wrapper">
                    <div className="header-line">
                      <img src={logoWhite} alt="Logo" />
                      <span></span>
                    </div>
                    <h6>Customer Verification Report</h6>
                  </div>
                </div>
                <div className="body">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Document Summary</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>Facial Biometric</b></td>
                        <td>{wcData?.face_match ? <Tag color={"green"}>Match</Tag> : <Tag color={"red"}>Not Matched</Tag>}</td>
                      </tr>
                      <tr>
                        <td><b>Sub Results</b></td>
                        <td>
                          {wcData?.sub_results === "Clear" &&
                            <Tag color={"green"}>Clear</Tag>
                          }
                          {wcData?.sub_results === "Caution" &&
                            <Tag color={"blue"}>Caution</Tag>
                          }
                          {wcData?.sub_results === "Suspected" &&
                            <Tag color={"gold"}>Suspected</Tag>
                          }
                          {wcData?.sub_results === "Rejected" &&
                            <Tag color={"red"}>Rejected</Tag>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td><b>World Check Result</b></td>
                        <td>
                          <Select
                            style={{
                              width: 120,
                            }}
                            value={status}
                            loading={statusLoading}
                            className='custom-select p-small'
                            onChange={handleChange}
                            placeholder="Select Any"
                            options={[
                              {
                                value: true,
                                label: 'Hit',
                              },
                              {
                                value: false,
                                label: 'No Hit',
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Customer Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>First Name</b></td>
                        <td>{wcData?.first_name ?? 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>Last Name</b></td>
                        <td>{wcData?.last_name ?? 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>DOB</b></td>
                        <td>{wcData?.dob ? moment(wcData?.dob).format("DD-MM-YYYY") : 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>Gender</b></td>
                        <td>{wcData?.gender ?? 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>Nationality</b></td>
                        <td>{wcData?.nationality ?? 'No Data'}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={2}>Document Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>Document Type</b></td>
                        <td>{wcData?.document_type !== '' ? wcData?.document_type : 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>Issuing Country</b></td>
                        <td>{wcData?.issue_country ?? 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>State/Region</b></td>
                        <td>{wcData?.region ?? 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>Date of Issue</b></td>
                        <td>{wcData?.issued_date ? moment(wcData?.issued_date).format("DD-MM-YYYY") : 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>Date of Expiry</b></td>
                        <td>{wcData?.expired_date ? moment(wcData?.expired_date).format("DD-MM-YYYY") : 'No Data'}</td>
                      </tr>
                      <tr>
                        <td><b>Document No</b></td>
                        <td>{wcData?.card_id?.length > 0 ? wcData?.card_id : 'No Data'}</td>
                      </tr>
                      {wcData.dynamicFields?.length > 0 && wcData.dynamicFields?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td><b>{data?.label}</b></td>
                            <td>{data?.value}</td>
                          </tr>
                        )
                      })}
                      {/* <tr>
                        <td><b>Fraud Assessment</b></td>
                        <td></td>
                      </tr> */}
                    </tbody>
                  </table>
                  {/* <table>
                    <thead>
                      <tr>
                        <th>Adverse Information</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="p-3">
                            <div className='custom-dragger w-100 p-0'>
                              <div className='custom-btn' onClick={() => uploader.current.click()}>
                                <input type="file" accept="image/*" name="" id="" ref={uploader} onChange={onFileUpload} className='d-none' />
                                <i className="fal fa-file-upload"></i>
                                <h6>Click to world check doc</h6>
                                <p>Only images with file size of max 5 MB is allowed.</p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
                <div className="pdf-footer">
                  <div className="website">
                    <img src={WorldIcon} alt="" />
                    <a href='https://northlark.com/' target="_blank">www.northlark.com</a>
                  </div>
                </div>
                {/* <button>Download</button> */}
              </div>
            </Spin>
          </div>
          <div className="col-md-6 h-100">
            <Spin spinning={loading}>
              <div className="d-flex justify-content-center align-items-center w-100 h-100">
                <div className='custom-dragger w-75 p-0'>
                  <div className='custom-btn' onClick={() => uploader.current.click()}>
                    <div>
                      <input type="file" name="" id="" ref={uploader} onChange={onFileUpload} className='d-none' />
                      {uploading ?
                        <i className="fad fa-spinner-third rotate-ani"></i>
                        :
                        wcFile ?
                          <i className="far fa-check-circle color-green"></i>
                          :
                          <i className="fal fa-file-upload"></i>
                      }
                      {(wcFile || wcData?.image) ?
                        <>
                          <h6>{wcFile ? wcFile?.name : `world-check-${id}.pdf`}</h6>
                          <p>Click to reupload file again.</p>
                        </>
                        :
                        <>
                          <h6>Click to upload file</h6>
                          <p>Only PDF with file size of max 10 MB is allowed.</p>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            {wcData?.image &&
              <button onClick={() => downloadFile(`data:application/pdf;base64,${wcData?.image}`, `world-check-${id}.pdf`)} className={`primary-btn mx-auto mt-3 ${loading ? 'btn-disabled' : ''}`}>Download Preview</button>
            }
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button className={`primary-btn download-btn me-4 ${loading ? 'btn-disabled' : ''}`} onClick={() => savePDF()}><i className="far fa-file-pdf"></i> Download</button>
          <button className={`submit-btn px-5 ${(finalLoading || wcData?.status == "APPROVED") ? 'btn-disabled' : ''}`} onClick={updateFinalStatus}>
            <i className={`far ${finalLoading ? 'fa-spinner-third' : 'fa-check-circle'}`}></i>
            &nbsp; Submit</button>
        </div>
      </div>
    </section>
  )
}
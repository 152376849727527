import axios from "axios";
class CountryRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getAllCountry() {
        let url = `/countries/country`;
        const response = await axios({method: 'get', url})
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getAllNationality() {
        let url = `/countries/nationality`;
        const response = await axios({method: 'get', url})
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }

    async getStateByCountry(code) {
        let url = `/countries/country?region=${code}`;
        const response = await axios({method: 'get', url})
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }

}

export default new CountryRepository();
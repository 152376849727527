import axios from "axios";
class LoginRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async login(payload) {
        let url = `/auth/login`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }
    async signup(payload) {
        let url = `/auth/register`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }
    async allAccounts() {
        let url = `/auth/getusers`
        const response = await axios({ method: 'get', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }

    async updateUser(payload) {
        let url = `/auth/updateuser`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }

    async updatePass(payload) {
        let url = `/auth/updatepassword`;
        const response = await axios({ method: 'put', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }

    async deleteUser(id) {
        let url = `/auth/delete?id=${id}`;
        const response = await axios({ method: 'put', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }

    async verifyOtp(payload) {
        let url = `/auth/verifyfa`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }

    async update2fa(payload) {
        let url = `/auth/updatefa`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: error }));
        return response;
    }
}

export default new LoginRepository();
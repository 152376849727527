import axios from 'axios'

class TrackRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getDocStatus(trackId) {
        const response = await axios({ method: 'get', url: '/track', data: trackId })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async getTracks(trackId) {
        let url = '';
        if (trackId) {
            url = `/track/alltracks?id=${trackId}`;
        } else {
            url = `/track/alltracks`;
        }
        const response = await axios({ method: 'get', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async searchTrack(trackId) {
        let url = `/track/search?searchTerm=${trackId}`;
        const response = await axios({ method: 'get', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async filterByDateRange(dates) {
        let url = `/track/daterange?startDate=${dates[0]}&endDate=${dates[1]}`;
        const response = await axios({ method: 'get', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async getProcessedData(id, status) {
        let url = `/getImage/${status}/${id}`;
        const response = await axios({ method: 'get', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async applyManual(payload) {
        let url = `/manual-approve`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async sendMail(payload) {
        let url = `/sendMail`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async updateTracks(payload) {
        let url = `/track/updatetracks`;
        const response = await axios({ method: 'put', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async updateFA(payload) {
        let url = `/track/updatefa`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async getWcData(id) {
        let url = `/track/worldcheck?id=${id}`;
        const response = await axios({ method: 'get', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async uploadWcReport(payload) {
        let url = `/track/updateworldcheckreport`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async updateWorldCheckStatus(payload) {
        let url = `/track/updateworldcheckstatus`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async updateFinalStatus(payload) {
        let url = `/track/updatefinalstatus`;
        const response = await axios({ method: 'post', url, data: payload })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
    async deleteTrack(id) {
        let url = `/track/delete?id=${id}`;
        const response = await axios({ method: 'put', url })
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new TrackRepository();
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, Link } from "react-router-dom";
import * as XLSX from 'xlsx';

const FileViewer = () => {
    const location = useLocation();
    const { file, mimeType } = location.state || {};



    if (!file) {
        return (
            <>
                <div>No file to display</div>
            </>
        );
    }

    return (
        <>
            <div style={{ float: "right" }}>
                <Link to="/app/tracks">
                    <button className="secondary-btn mb-4">Back</button>
                </Link>
            </div>
            <div className='text-center mt-5'>
                {mimeType === 'image/png' || mimeType === 'image/jpeg' ?
                    <img src={`data:${mimeType};base64,${file}`} className='img-fluid' /> :
                    <iframe
                        src={`data:${mimeType};base64,${file}`}
                        style={{ border: 'none', width: '100%', height: '100vh' }}
                        title="File Viewer"
                    />}
            </div>
        </>
    );
};

export default FileViewer;

import React, {useState, useEffect, useRef } from 'react'
import { Select, Tooltip, Radio, Popover, Modal, Checkbox, Skeleton } from 'antd'
import Draggable from 'react-draggable'
import { useNavigate, useParams } from 'react-router-dom';

import { sendNotify } from '../helper/notification';

import TrackRep from '../repository/TrackRep';

export default function FraudAssessment() {
    const { id } = useParams();
    const docRef = useRef()
    const sampleDocRef = useRef()
    const navigate = useNavigate();
    var currentUrl = window.location.href;

    const [docData, setDocData] = useState(null);
    const [docVersions, setDocVersions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);
    
    const [imgStyle, setImgStyle] = useState({rotate: 0, zoom: 1, flip: false})
    const [sampleImgStyle, setSampleImgStyle] = useState({rotate: 0, zoom: 1, flip: false})
    const [isCopied, setIsCopied] = useState(false);
    const [formData, setFormData] = useState({});
    const [disableId, setDisableId] = useState(0);

    const [selectedFlags, setSelectedFlags] = useState([]);
    const [selectedDocVersion, setSelectedDocVersion] = useState(0);

    const [selectConclusive, setSelectConclusive] = useState([]);
    const [selectODP, setSelectODP] = useState('');

    const [unsupported, setUnsupported] = useState(null)
    const [iqFail, setIqFail] = useState(null)
    const [language, setLanguage] = useState(null)
    const [escalate, setEscalate] = useState(null)

    useEffect(() => {
        // console.log(id, status);
        getImages(id)
      }, []);

      const [time, setTime] = useState({
        sec: 0,
        min: 0,
        hr: 0
    });
    
      const [intervalId, setIntervalId] = useState();
    
      const updateTimer = () => {
        setTime((prev) => {
          let newTime = { ...prev };
          // update sec and see if we need to increase min
          if (newTime.sec < 59) newTime.sec += 1;
          else {
            newTime.min += 1;
            newTime.sec = 0;
          }
          // min has increased in *newTime* by now if it was updated, see if it has crossed 59
          if (newTime.min === 60) {
            newTime.min = 0;
            newTime.hr += 1;
          }
    
          return newTime;
        });
      };
    
      const pauseOrResume = () => {
        if (!intervalId) {
          let id = setInterval(updateTimer, 1000);
          setIntervalId(id);
        } else {
          clearInterval(intervalId);
          setIntervalId("");
        }
      };
    
      const reset = () => {
        clearInterval(intervalId);
        setTime({
          sec: 0,
          min: 0,
          hr: 0
        });
      }; 
    
    const getImages = (id) =>{
        TrackRep.getTracks(id).then(response => {
            if (response) {
              if (response?.error) {
                sendNotify('error',response?.message);
                setImageLoading(false)
              } else {
                // console.log(response);
                sendNotify('success', response?.message);
                setDocData(response?.data)
                let docVersion = response?.data?.version
                if (response?.data?.versions.length > 0) {
                    let versions = response?.data?.versions?.map((sdv, index)=>{
                        return({
                            value: index,
                            label: <div className='version-doc'><img src={sdv.image_front
                            } /><h6>{sdv.version}</h6></div>
                        })
                    })
                    setDocVersions(versions)
                    let find = response?.data?.versions.findIndex(v => v.version === docVersion)
                    // console.log(find);
                    setSelectedDocVersion(find == -1 ? 0 : find)
                    // sendNotify('error', `${docVersion} version sample document not available`);
                }


                let faData = response?.data?.fraud_assesment
                let arr = []

                setUnsupported(faData?.unsupported_documents)
                setLanguage(faData?.language)
                setIqFail(faData?.iq_fail)
                setEscalate(faData?.escalate)

                if (faData?.conculsive_quality.length > 0) {
                    arr.push('CQ')
                    conclusiveOnChange(faData?.conculsive_quality)
                }
                if (faData?.ODP) {
                    arr.push('ODP')
                    odpOnChange(faData?.ODP)
                }
                if (faData?.face_detection) {
                    arr.push('FD')
                    toggleFlag('FD')
                }
                if (faData?.picture_face_integrity) {
                    arr.push('PFI')
                    toggleFlag('PFI')
                }
                if (faData?.security_features) {
                    arr.push('SF')
                    toggleFlag('SF')
                }
                if (faData?.shape_template) {
                    arr.push('ST')
                    toggleFlag('ST')
                }
                if (faData?.fonts) {
                    arr.push('F')
                    toggleFlag('F')
                }
                if (faData?.digital_tampering) {
                    arr.push('DT')
                    toggleFlag('DT')
                }
                setSelectedFlags(arr)
                setTime(response?.data?.fa_time ?? {
                    sec: 0,
                    min: 0,
                    hr: 0
                  })
                  if (response?.data?.status != "APPROVED") {
                      pauseOrResume()
                  }
                setImageLoading(false)
              }
            }
          })
        .catch(error => {
            sendNotify('error',error)
            setImageLoading(false)
        })
    }

    docRef.current?.addEventListener('wheel', function (event) { 
        // Zoom in or out based on the scroll direction 
        let direction = event.deltaY > 0 ? -1 : 1;
        if (direction == 1) {
            editImg('zoomIn')
        }else{
            editImg('zoomOut')
        }
    });

    sampleDocRef.current?.addEventListener('wheel', function (event) { 
        // Zoom in or out based on the scroll direction
        let direction = event.deltaY > 0 ? -1 : 1;
        if (direction == 1) {
            editSampleImg('zoomIn')
        }else{
            editSampleImg('zoomOut')
        }
    }); 

    const getChange = (key, value) => {
        let obj = {...formData}
        obj[key] = value
        setFormData(obj)
    };

    function editImg(action) {
        let style = {...imgStyle}
        switch (action) {
            case 'rorateX':
                style['rotate'] -= 45
                break;
            case 'rorateY':
                style['rotate'] += 45
                break;
            case 'zoomIn':
                if (style['zoom'] !== 10) {
                    style['zoom'] += 0.5
                }
                break;
            case 'zoomOut':
                if (style['zoom'] !== 0.5) {
                    style['zoom'] -= 0.5
                }
                break;
            case 'flip':
                style['flip'] = !style['flip']
                break;
        }

        setImgStyle(style)
    }

    function editSampleImg(action) {
        let style = {...sampleImgStyle}
        switch (action) {
            case 'rorateX':
                style['rotate'] -= 45
                break;
            case 'rorateY':
                style['rotate'] += 45
                break;
            case 'zoomIn':
                if (style['zoom'] !== 10) {
                    style['zoom'] += 0.5
                }
                break;
            case 'zoomOut':
                if (style['zoom'] !== 0.5) {
                    style['zoom'] -= 0.5
                }
                break;
            case 'flip':
                style['flip'] = !style['flip']
                break;
        }

        setSampleImgStyle(style)
    }

    const copyDocLink = () => {
        navigator.clipboard.writeText(currentUrl)
        setIsCopied(true)
    }

    const conclusiveOptions = [
        {
            label: 'Obscured Data Points',
            value: 'Obscured Data Points',
        },
        {
            label: 'Obscured Security Features',
            value: 'Obscured Security Features',
        },
        {
            label: 'Abnormal Doc Features',
            value: 'Abnormal Doc Features',
        },
        {
            label: 'Digital Text Overlay',
            value: 'Digital Text Overlay',
        },
        {
            label: 'Corner Physically Removed',
            value: 'Corner Physically Removed',
        },
        {
            label: 'Punctured',
            value: 'Punctured',
        },
        {
            label: 'Digital Version',
            value: 'Digital Version',
        },
        {
            label: 'Missing Back',
            value: 'Missing Back',
        },
        {
            label: 'Expired Document',
            value: 'Expired Document',
        },
    ];

    const odpOptions = [
        {
          label: 'Photo of a screen',
          value: 'Photo of a screen',
        },
        {
          label: 'Screenshot',
          value: 'Screenshot',
        },
        {
          label: 'Document printed on paper',
          value: 'Document printed on paper',
        },
        {
          label: 'Scan',
          value: 'Scan',
        },
    ];

    const conclusiveOnChange = (value) =>{
        onChangeFlag(value, 'CQ')
        setSelectConclusive(value)
    }

    const odpOnChange = (value) =>{
        onChangeFlag(value, 'ODP')
        setSelectODP(value)
    }

    const onChangeFlag = (value, label) =>{
        let arr = [...selectedFlags]
        let index = arr.findIndex(a => a == label)
        if (value.length == 0) {
            arr.splice(index, 1);
            if (label == 'CQ') {
                setDisableId(0)
            }
        } else {
            if (index == -1) {
                arr.push(label)
                if (label == 'CQ') {
                    setDisableId(2)
                }
            }
        }
        setSelectedFlags(arr)
    }

    const changeDocVersion = (e)=>{
        setSelectedDocVersion(e.target.value)
    }

    const onChange = (e) =>{
        console.log(e);
    }

    const confirm = (e) => {
        console.log(e);
    };

    const unsupportedOptions = [
        {
          label: 'Document not supported',
          value: 'Document not supported',
        },
        {
          label: 'Template not available in the confluence',
          value: 'Template not available in the confluence',
        },
    ];

    const iqFailOptions = [
        {
          label: 'Missing front',
          value: 'Missing front',
        },
        {
          label: 'No document in image',
          value: 'No document in image',
        },
        {
          label: 'Two documents uploaded',
          value: 'Two documents uploaded',
        },
    ];

    const languageOptions = [
        {
          label: 'Latin',
          value: 'Latin',
        },
        {
          label: 'Japaneese',
          value: 'Japaneese',
        },
        {
          label: 'Chinese',
          value: 'Chinese',
        },
        {
            label: 'Cyrillic',
            value: 'Cyrillic',
        },
        {
            label: 'Vietnamese',
            value: 'Vietnamese',
        },
        {
            label: 'Korean',
            value: 'Korean',
        }
    ];

    const escalateOptions = [
        {
          label: 'Application error',
          value: 'Application error',
        },
        {
          label: 'Others',
          value: 'Others',
        },
    ];

    const toggleFlag = (flag) =>{
        let arr = [...selectedFlags]
        let index = arr.findIndex(a => a === flag)
        if (index !== -1) {
            arr.splice(index, 1);
            let find = arr.filter(a => a !== 'FD')
            if (find.length === 0) {
                setDisableId(0)
            }
        } else {
            arr.push(flag)
            if (flag != 'FD') {
                setDisableId(1)
            }
        }
        setSelectedFlags(arr)
    }

    const removeFlag = (flag) =>{
        let arr = [...selectedFlags]
        let index = arr.findIndex(a => a === flag)
        arr.splice(index, 1);
        if (flag == 'CQ') {
            setDisableId(0)
            setSelectConclusive([])
        }
        if (flag == 'ODP') {
            setSelectODP('')
        }
        setSelectedFlags(arr)
    }

    const backToList = () =>{
        navigate(`/app/process/${id}`)
    }

    const onSubmit = () =>{
        setLoading(true)
        let payload = {
            status: "Fraud_assesment",
            trackId: id,
            fa_time: time
        }
        let flags = []
        selectedFlags.length > 0 && selectedFlags.map(flag =>{
            let res = ''
            switch (flag) {
                case 'FD':
                    res = "face_detection"
                    break;
                case 'PFI':
                    res = "picture_face_integrity"
                    break;
                case 'ST':
                    res = "shape_template"
                    break;
                case 'SF':
                    res = "security_features"
                    break;
                case 'F':
                    res = "fonts"
                    break;
                case 'DT':
                    res = "digital_tampering"
                    break;
                default:
                    res = ''
                    break;
            }
            if (res != '') {
                flags[res] = true
            }
        })

        payload['fraud_assesment'] = {
            unsupported_documents: unsupported,
            iq_fail: iqFail,
            language: language,
            escalate: escalate,
            conculsive_quality: selectConclusive,
            ODP: selectODP,
            ...flags
        }
        TrackRep.updateFA(payload).then(response => {
            if (response) {
              setLoading(false)
              if (response?.error) {
                sendNotify('error', response.message)
              } else {
                sendNotify('success', "Approval successfully")
                navigate(`/app/world-check/${id}`)
              }
            }
          })
            .catch(error => {
              setLoading(false)
              sendNotify('error', error)
            })
    }

  return (
    <section className=''>
      <div className="page-header flex-md-row flex-column">
        <div className="d-flex align-items-center">
            <button className='primary-btn' onClick={backToList}><i className="far fa-backward"></i> Back</button>
            <h1 className='ms-2'>Fraud Assessment</h1>
        </div>
        <div className="step-wrapper">
            <button>1</button>
            <button className='active'>2</button>
            <button>3</button>
        </div>
        <div>
            <p className='task-timer'>{`${time.hr < 10 ? 0 : ""}${time.hr}:${time.min < 10 ? 0 : ""}${time.min}:${time.sec < 10 ? 0 : ""}${time.sec}`}</p>
        </div>
      </div>
      <div className="task">
        <div className="d-flex">
            <div className="w-5">
                <div className="d-flex flex-column justify-content-between h-100">
                    <div>
                        <Popover
                            title={'Versions'}
                            placement="right"
                            content={
                                <div className='scroll-view'>
                                    <Radio.Group className='d-flex flex-column' defaultValue={0} value={selectedDocVersion} options={docVersions} onChange={changeDocVersion} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-folder-open"></i></button>
                        </Popover>
                        <button className='rounded-btn'><i className="fas fa-info"></i></button>
                    </div>
                    <div>
                        <Popover
                            title={'Unsupported Documents'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={unsupported} options={unsupportedOptions} onChange={(e)=>setUnsupported(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-flag-alt"></i></button>
                        </Popover>
                        <Popover
                            title={'IQ Fail'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={iqFail} options={iqFailOptions} onChange={(e)=>setIqFail(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-exclamation"></i></button>
                        </Popover>
                        <Popover
                            title={'Language'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={language} options={languageOptions} onChange={(e)=>setLanguage(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-comment"></i></button>
                        </Popover>
                        <Popover
                            title={'Escalate'}
                            placement="right"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' value={escalate} options={escalateOptions} onChange={(e)=>setEscalate(e.target.value)} />
                                </div>
                            }
                            onConfirm={confirm}
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='rounded-btn'><i className="fas fa-arrow-up"></i></button>
                        </Popover>
                    </div>
                    <div>
                        
                    </div>
                </div>
            </div>
            <div className="w-68 me-2">
                        <div className="task-card">
                                <div className='w-50 px-4 text-center border-right'>
                                    <div className="data-list">
                                        <h6><span>Country:</span> {docData?.issue_country}</h6>
                                        <h6><span>Document Type:</span> {docData?.document_type}</h6>
                                        <h6><span>Issuing State/Region:</span> {docData?.region}</h6>
                                    </div>
                                    {imageLoading ? 
                                        <Skeleton.Image active className='custom-image-loader bg-white' />
                                        :
                                        <div ref={sampleDocRef} className={`doc-img ${imgStyle.flip ? 'flip' : ''}`} style={{ transform: `scale(${sampleImgStyle.zoom})`}}>
                                            <img className='back' src={docData?.versions[selectedDocVersion]?.image_back}  alt="" />
                                            <img className='front' src={docData?.versions[selectedDocVersion]?.image_front} alt="" />
                                        </div>
                                    }
                                </div>
                                <div className="w-50 px-4 text-center">
                                    <Draggable position={null}>
                                        <div>
                                            {imageLoading ? 
                                                <Skeleton.Image active className='custom-image-loader bg-white' />
                                                :
                                                <div ref={docRef} className={`doc-img ${imgStyle.flip ? 'flip' : ''}`} style={{rotate: `${imgStyle.rotate}deg`, transform: `scale(${imgStyle.zoom})`}}>
                                                    <img className='back' src={`data:image/jpeg;base64,${docData?.image_back}`} alt="" />
                                                    <img className='front' src={`data:image/jpeg;base64,${docData?.image_front}`} alt="" />
                                                </div>
                                            }
                                        </div>
                                    </Draggable>
                                    <div className="options">
                                        <div className="img-options">
                                            <button onClick={()=>editImg('rorateX')}><i className="fas fa-undo"></i></button>
                                            <button onClick={()=>editImg('zoomIn')}><i className="far fa-search-plus"></i></button>
                                            <button onClick={()=>editImg('flip')}><i className="far fa-window-restore"></i></button>
                                            <button onClick={()=>editImg('zoomOut')}><i className="far fa-search-minus"></i></button>
                                            <button onClick={()=>editImg('rorateY')}><i className="fas fa-redo-alt"></i></button>
                                        </div>
                                        <div className='doc-link'>
                                            <p>{currentUrl}</p>
                                            <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                                                <button onClick={copyDocLink}><i className={`${isCopied ? 'fad' : 'far'} fa-copy`}></i></button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                        </div>
            </div>
            <div className="w-25 ms-2">
                <div className="task-options">
                    <Popover
                        icon={false}
                        title={false}
                        placement="left"
                        content={
                            <div>
                                <Checkbox.Group className='d-flex flex-column' value={selectConclusive} options={conclusiveOptions} onChange={conclusiveOnChange} />
                            </div>
                        }
                        onConfirm={confirm}
                        okText="Submit"
                        cancelText="Cancel"
                    >
                        <button className={`report-options mb-2 ${disableId == 1 ? 'btn-disabled' : ''}`}> Conclusive Quality <span className={`flag ${selectedFlags.findIndex(s => s == 'CQ') != -1 ? 'active' : ''}`} onClick={()=>removeFlag('CQ')}>Flag</span></button>
                    </Popover>
                    <Popover
                        title={'Original Document Present'}
                        placement="left"
                        content={
                            <div>
                                <Radio.Group className='d-flex flex-column' value={selectODP} options={odpOptions} onChange={(e)=>odpOnChange(e.target.value)} />
                            </div>
                        }
                        onConfirm={confirm}
                        okText="Submit"
                        cancelText="Cancel"
                    >
                        <button className='report-options mb-2'>ODP <span onClick={()=>removeFlag('ODP')} className={`flag ${selectedFlags.findIndex(s => s == 'ODP') != -1 ? 'active' : ''}`}>Flag</span></button>
                    </Popover>
                        <button className='report-options mb-2'><div className='d-flex align-items-center'><i className="far fa-smile"></i> Face Detection</div> <span onClick={()=>toggleFlag('FD')} className={`flag ${selectedFlags.findIndex(s => s == 'FD') != -1 ? 'active' : ''}`}>Flag</span></button>

                        <button className={`report-options mb-2 ${disableId == 2 ? 'btn-disabled' : ''}`}><div className='d-flex align-items-center'><i className="far fa-image"></i> Picture Face Integrity</div> <span onClick={()=>toggleFlag('PFI')} className={`flag ${selectedFlags.findIndex(s => s == 'PFI') != -1 ? 'active' : ''}`}>Flag</span></button>

                        <button className={`report-options mb-2 ${disableId == 2 ? 'btn-disabled' : ''}`}><div className='d-flex align-items-center'><i className="far fa-id-card"></i> Shape & Template</div> <span onClick={()=>toggleFlag('ST')} className={`flag ${selectedFlags.findIndex(s => s == 'ST') != -1 ? 'active' : ''}`}>Flag</span></button>

                        <button className={`report-options mb-2 ${disableId == 2 ? 'btn-disabled' : ''}`}>
                            <div className='d-flex align-items-center'>
                            <i className="far fa-shield-check"></i> Security Features
                            </div> <span onClick={()=>toggleFlag('SF')} className={`flag ${selectedFlags.findIndex(s => s == 'SF') != -1 ? 'active' : ''}`}>Flag</span>
                        </button>

                        <button className={`report-options mb-2 ${disableId == 2 ? 'btn-disabled' : ''}`}>
                            <div className='d-flex align-items-center'>
                            <i className="far fa-font-case"></i> Fonts
                            </div> <span onClick={()=>toggleFlag('F')} className={`flag ${selectedFlags.findIndex(s => s == 'F') != -1 ? 'active' : ''}`}>Flag</span>
                        </button>

                        <button className={`report-options mb-2 ${disableId == 2 ? 'btn-disabled' : ''}`}>
                            <div className='d-flex align-items-center'>
                            <i className="far fa-edit"></i> Digital Tampering
                            </div> <span onClick={()=>toggleFlag('DT')} className={`flag ${selectedFlags.findIndex(s => s == 'DT') != -1 ? 'active' : ''}`}>Flag</span>
                        </button>

                    <div className="text-center mt-3">
                        {/* <Popover
                            title="Leave CP"
                            placement="left"
                            content={
                                <div>
                                    <Radio.Group className='d-flex flex-column' options={leaveCP} onChange={onChange} />
                                </div>
                            }
                            onConfirm={confirm} 
                            okText="Submit"
                            cancelText="Cancel"
                        >
                            <button className='leave-btn w-50 me-2'>Leave CP</button>
                        </Popover> */}
                        <button className={`submit-btn w-50 ${loading ? 'btn-loading' : ''}`} onClick={onSubmit}>
                            <i className={`far ${loading ? 'fa-spinner-third' : 'fa-paper-plane'}`}></i>
                            &nbsp;Submit</button>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* <Modal title="Other" className='custom-modal' open={showComment} width={500} centered onOk={()=>setShowComment(false)} okText={'Save'} onCancel={()=>setShowComment(false)}>
            <div className="input-box">
            <label htmlFor="comments">Comments:</label>
            <textarea name="comments" id='comments' placeholder='Enter you comments' className='w-100' rows="5"></textarea>
            </div>
      </Modal> */}
    </section>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, notification, Select } from 'antd';
import { useParams, useNavigate } from "react-router-dom";
// import dayjs from 'dayjs';
// import { Country, State, City } from "country-state-city";
import moment from 'moment';

import { sendNotify } from '../helper/notification';
import TrackRep from '../repository/TrackRep';
import CountryRep from '../repository/countryRep';
import UploadRep from '../repository/uploadRep';

export default function ManualForm(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  let details = useSelector((state) => state.auth.userDetails)

  // Note: Only add required fields to this object.
  let formSchema = {
    issue_country: '',
    document_type: '',
    region: '',
    face_match: '',
    first_name: '',
    last_name: '',
    dob: '',
    gender: '',
    // country: '',
    card_id: '',
    issued_date: '',
    expired_date: ''
  }

  const [formValues, setFormValues] = useState(formSchema);
  const [errorObj, setErrorObj] = useState(formSchema);
  const [loading, setLoading] = useState(false);

  const [countryLoading, setCountryLoading] = useState(false);
  const [nationalityLoading, setNationalityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [country, setCountry] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [docType, setDocType] = useState([]);
  const [state, setState] = useState([]);
  const [newFields, setNewFields] = useState([]);

  useEffect(() => {
    let data = props.formData
    let values = { ...formValues }

    values.issue_country = data?.issue_country ?? data?.country
    values.region = data?.region
    values.document_type = data?.document_type
    values.face_match = data?.face_match ? true : false
    console.log(data, 'data d');

    // values.first_name = Array.isArray(data?.name) ? data?.name[0] : data?.name
    // values.last_name = Array.isArray(data?.name) ? data?.name?.length > 1 ? data?.name[1] : '' : ''
    values.first_name = data?.first_name
    values.last_name = data?.last_name
    values.dob = data?.dob ? moment(data?.dob).format("YYYY-MM-DD") : ''
    values.gender = data?.gender
    values.nationality = data?.nationality ?? null
    values.card_id = Array.isArray(data?.card_id) ? data?.card_id[0] : data?.card_id
    values.issued_date = data?.issued_date ? moment(data?.issued_date).format("YYYY-MM-DD") : ''
    values.expired_date = data?.expired_date ? moment(data?.expired_date).format("YYYY-MM-DD") : ''
    setFormValues(values)
    setNewFields((data?.dynamicFields || data?.dynamicFields?.length > 0) ? data?.dynamicFields : [])
  }, [props.formData])

  useEffect(() => {
    getCounty()
    getNationality()
  }, []);

  useEffect(() => {
    getState()
    // getDocType(formValues['issue_country'])
  }, [formValues['issue_country']]);

  const getCounty = () => {
    let arr = []
    let obj = {}
    // let country = Country.getAllCountries()
    setCountryLoading(true)
    CountryRep.getAllCountry().then(response => {
      if (response) {
        setCountryLoading(false)
        if (response?.error) {
          sendNotify('error', response.message)
          setCountry([])
        } else {
          response?.data?.length > 0 && response?.data.map((option) => {
            obj['label'] = `${option?.name} - ${option?.code}`
            obj['value'] = option?.code
            arr.push(obj)
            obj = {}
          })
          setCountry(arr)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
    })
  }

  const getNationality = () => {
    let arr = []
    let obj = {}
    // let country = Country.getAllCountries()
    setNationalityLoading(true)
    CountryRep.getAllNationality().then(response => {
      if (response) {
        setNationalityLoading(false)
        if (response?.error) {
          sendNotify('error', response.message)
          setNationality([])
        } else {
          response?.data?.length > 0 && response?.data.map((option) => {
            obj['label'] = `${option?.code} - ${option?.country} - ${option?.nationality}`
            obj['value'] = option?.code
            arr.push(obj)
            obj = {}
          })
          setNationality(arr)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
    })
  }

  const getState = () => {
    let arr = []
    let obj = {}
    setStateLoading(true)
    // let state = State.getStatesOfCountry(formValues['issue_country'])
    CountryRep.getStateByCountry(formValues['issue_country']).then(response => {
      if (response) {
        setStateLoading(false)
        if (response?.error) {
          sendNotify('error', response.message)
          setState([])
        } else {
          response?.data?.region?.length > 0 && response?.data?.region?.map((option) => {
            obj['label'] = `${option?.region_name} - ${option?.region_code}`
            obj['value'] = option?.region_name
            arr.push(obj)
            obj = {}
          })
          setState(arr)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
    })
  }

  const getDatePicker = (date, dateString) => {
    // console.log(date, dateString);
    getInputValue('dob', dateString)
  };

  const getExpiryDate = (date, dateString) => {
    // console.log(date, dateString);
    getInputValue('expired_date', dateString)
  };

  const getDateIssue = (date, dateString) => {
    // console.log(date, dateString);
    getInputValue('issued_date', dateString)
  };

  const getInputValue = (field, value) => {
    let values = { ...formValues }
    let errors = { ...errorObj }
    values[field] = value
    setFormValues(values)
    if (value !== null || value !== '') {
      errors[field] = ''
      setErrorObj(errors)
    } else {
      errors[field] = `${field?.replace(/-|_/g, ' ')} was required`
      setErrorObj(errors)
    }
  }

  function checkEmpty() {
    let values = { ...formValues }
    let errors = { ...errorObj }
    let emptyArr = []
    for (var key in values) {
      if (values[key] === "" || errors[key] !== '') {
        emptyArr.push(key)
      }
    }
    return emptyArr;
  }

  function formValidation() {
    let errors = { ...errorObj }
    let empty = checkEmpty()
    if (empty?.length > 0) {
      empty.forEach((field) => {
        errors[field] = `${field?.replace(/-|_/g, ' ')} was required`
      })
    } else {
      return true
    }
    setErrorObj(errors)
    return false
  }

  function convertToSlug(Text) {
    return Text
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/[^\w-]+/g, '')
      ;
  }

  const submitForm = () => {
    // if (formValidation()) {
    setLoading(true)
    let payload = { ...formValues }
    // newFields.forEach((item)=>{
    //   payload[convertToSlug(item.label)] = item.value
    // })
    // console.log(newFields);
    payload['status'] = 'update'
    payload['trackId'] = id
    // payload['name'] = [payload['first_name'], payload['last_name']]
    // delete payload['last_name']
    payload['dynamicFields'] = newFields
    payload['recently_changed'] = details.userId
    payload['classification_time'] = props.taskTime

    TrackRep.updateTracks(payload).then(response => {
      if (response) {
        setLoading(false)
        if (response?.error) {
          console.log('err');
          sendNotify('error', response.message)
        } else {
          sendNotify('success', "Approval successfully")
          navigate(`/app/fraud-assessment/${id}`)
          setFormValues(formSchema)
          setErrorObj(formSchema)
        }
      }
    })
      .catch(error => {
        sendNotify('error', error)
      })
    // } else {
    //   sendNotify('error', "Some form field(s) are missing!")
    // }
  }

  const addInput = () => {
    let arr = [...newFields]
    let newInput = {
      label: 'Label',
      value: ''
    }
    arr.push(newInput)
    setNewFields(arr)
  }

  const removeInput = (index) => {
    let arr = [...newFields]
    arr.splice(index, 1);
    setNewFields(arr)
  }

  const getData = (value, index, key) => {
    let arr = [...newFields]
    arr[index][key] = value
    setNewFields(arr)
  }

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getDocType = (code) => {
    // console.log(code,'getDocType');
    if (code) {
      let arr = []
      let obj = {}
      UploadRep.getDocOptionsByCountry(code).then(response => {
        if (response) {
          setCountryLoading(false)
          if (response?.error) {
            sendNotify('error', response.message)
            setDocType([])
          } else {
            let data = response?.data?.documents
            data?.length > 0 && data.map((option) => {
              obj['label'] = `${option}`
              obj['value'] = option
              arr.push(obj)
              obj = {}
            })
            setDocType(arr)
          }
        }
      }).catch(error => {
        sendNotify('error', 'Failed to fetch Data')
      })
    }
  }

  return (
    <div className='custom-form'>
      <h4 className='mb-2'>Classification</h4>
      <div className={`input-box ${errorObj['issue_country'] ? 'input-error' : ''}`}>
        <label htmlFor="poc">Issuing Country <span className='err-meg'>{errorObj['issue_country']}</span></label> <br />
        <Select
          showSearch
          className='custom-select w-100'
          placeholder="Select Issue Country"
          value={formValues['issue_country']}
          onChange={(value) => getInputValue('issue_country', value)}
          options={country}
          filterOption={filterOption}
          loading={countryLoading}
          disabled={countryLoading}
        />
      </div>
      <div className={`input-box ${errorObj['document_type'] ? 'input-error' : ''}`}>
        <label htmlFor="poc">Document Type <span className='err-meg'>{errorObj['document_type']}</span></label> <br />
        {/* <input type="text" name='document_type' id='document_type' placeholder='Enter document type' value={formValues['document_type']} onChange={(e) => getInputValue('document_type', e.target.value)} /> */}
        <Select
          className='custom-select w-100'
          placeholder="Select Gender"
          value={formValues['document_type']}
          onChange={(value) => getInputValue('document_type', value)}
          options={docType}
        />
      </div>
      <div className={`input-box ${errorObj['region'] ? 'input-error' : ''}`}>
        <label htmlFor="poc">State/Region <span className='err-meg'>{errorObj['region']}</span></label> <br />
        <Select
          showSearch
          className='custom-select w-100'
          placeholder="Select Region"
          value={formValues['region']}
          onChange={(value) => getInputValue('region', value)}
          options={state}
          filterOption={filterOption}
          loading={stateLoading}
          disabled={stateLoading}
        />
      </div>
      <div className={`input-box ${errorObj['face_match'] ? 'input-error' : ''}`}>
        <label htmlFor="poc">Facial Biometric Results <span className='err-meg'>{errorObj['face_match']}</span></label> <br />
        <Select
          className='custom-select w-100'
          placeholder="Select Gender"
          value={formValues['face_match']}
          onChange={(value) => getInputValue('face_match', value)}
          options={[
            {
              value: true,
              label: 'Match',
            },
            {
              value: false,
              label: 'Not Matched',
            }
          ]}
        />
      </div>


      <h4 className='mt-5 mb-2'>Enter details</h4>
      <div className={`input-box ${errorObj['first_name'] ? 'input-error' : ''}`}>
        <label htmlFor="name">First Name <span className='err-meg'>{errorObj['first_name']}</span></label>
        <input type="text" name='first_name' id='first_name' placeholder='Enter first Name' value={formValues['first_name']} onChange={(e) => getInputValue('first_name', e.target.value)} />
      </div>
      <div className={`input-box ${errorObj['last_name'] ? 'input-error' : ''}`}>
        <label htmlFor="name">Last Name <span className='err-meg'>{errorObj['last_name']}</span></label>
        <input type="text" name='last_name' id='last_name' placeholder='Enter last name' value={formValues['last_name']} onChange={(e) => getInputValue('last_name', e.target.value)} />
      </div>
      <div className={`input-box ${errorObj['dob'] ? 'input-error' : ''}`}>
        <label htmlFor="dob">Date of Birth <span className='err-meg'>{errorObj['dob']}</span></label> <br />
        <input type="text" name='dob' id='dob' placeholder='YYYY-MM-DD' value={formValues['dob']} onChange={(e) => getInputValue('dob', e.target.value)} />
      </div>
      <div className={`input-box ${errorObj['gender'] ? 'input-error' : ''}`}>
        <label htmlFor="poc">Gender <span className='err-meg'>{errorObj['gender']}</span></label> <br />
        <Select
          className='custom-select w-100'
          placeholder="Select Gender"
          value={formValues['gender']}
          onChange={(value) => getInputValue('gender', value)}
          options={[
            {
              value: 'male',
              label: 'Male',
            },
            {
              value: 'female',
              label: 'Female',
            }
          ]}
        />
      </div>
      <div className={`input-box ${errorObj['country'] ? 'input-error' : ''}`}>
        <label htmlFor="clientName">Nationality <span className='err-meg'>{errorObj['country']}</span></label>
        {/* <input type="text" name='country' id='country' placeholder='Enter Nationality' value={formValues['country']} onChange={(e) => getInputValue('country', e.target.value)} /> */}
        <Select
          showSearch
          className='custom-select w-100'
          placeholder="Select Nationality"
          value={formValues['nationality']}
          onChange={(value) => getInputValue('nationality', value)}
          options={nationality}
          filterOption={filterOption}
          loading={nationalityLoading}
          disabled={nationalityLoading}
        />
      </div>
      <div className={`input-box ${errorObj['card_id'] ? 'input-error' : ''}`}>
        <label htmlFor="clientName">Document Number <span className='err-meg'>{errorObj['card_id']}</span></label>
        <input type="text" name='card_id' id='card_id' placeholder='Enter Card ID' value={formValues['card_id']} onChange={(e) => getInputValue('card_id', e.target.value)} />
      </div>
      <div className={`input-box ${errorObj['issued_date'] ? 'input-error' : ''}`}>
        <label htmlFor="name">Date of Issue <span className='err-meg'>{errorObj['issued_date']}</span></label>
        <input type="text" name='issued_date' id='issued_date' placeholder='YYYY-MM-DD' value={formValues['issued_date']} onChange={(e) => getInputValue('issued_date', e.target.value)} />
      </div>
      <div className={`input-box ${errorObj['expired_date'] ? 'input-error' : ''}`}>
        <label htmlFor="name">Date of Expiry <span className='err-meg'>{errorObj['expired_date']}</span></label>
        <div className="d-flex">
          <input type="text" name='expired_date' id='expired_date' placeholder='YYYY-MM-DD' value={formValues['expired_date']} onChange={(e) => getInputValue('expired_date', e.target.value)} />
          <button className='primary-btn btn-green ms-2' onClick={addInput}><i className="far fa-plus me-0"></i></button>
        </div>
      </div>
      {newFields?.length > 0 && newFields.map((newField, index) => {
        return (
          <div className={`input-box`} key={index}>
            <label key={index} contentEditable suppressContentEditableWarning={true} onBlur={(e) => getData(e.target.innerText, index, 'label')}>{newField.label}</label>
            <div className="d-flex">
              <input type="text" placeholder='Enter new data' value={newField.value} onChange={(e) => getData(e.target.value, index, 'value')} />
              <button className='primary-btn btn-red ms-2' onClick={() => removeInput(index)}><i className="far fa-trash-alt me-0"></i></button>
            </div>
          </div>
        )
      })}
      <div className="d-flex justify-content-center">
        <button className={`primary-btn ${loading ? 'btn-loading' : ''}`} onClick={submitForm}>
          <i className={`far ${loading ? 'fa-spinner-third' : 'fa-paper-plane'}`}></i>
          Submit</button>
      </div>
    </div>
  )
}
import React, { useEffect, useState } from 'react'
import { Table, Tag } from 'antd';
import { useNavigate } from "react-router-dom";
import moment from 'moment';

import { sendNotify } from '../helper/notification'
import TrackRep from '../repository/TrackRep'

export default function Track() {
  const navigate = useNavigate();

  const [trackLoading, setTrackLoading] = useState(false)
  const [trackList, setTrackList] = useState([])
  const [searchID, setSearchID] = useState('')

  useEffect(() => {
    getTracks()
  }, []);

  const openTrack = (id, status) => {
    // console.log(id, status);
    navigate(`/app/process/${id}`)
  }

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'key',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
    },
    {
      title: 'Client Name',
      dataIndex: 'client',
    },
    {
      title: 'Track ID',
      dataIndex: 'id',
    },
    {
      title: 'Current Status',
      key: 'status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status === "PENDING"
            ? <Tag color={"gold"} key={status}>{status}</Tag> :
            status === "REJECTED" ?
              <Tag color={"red"} key={status}>{status}</Tag> :
              <Tag color={"green"} key={status}>{status}</Tag>}
        </>
      ),
    },
    // {
    //   title: <p className='w-300 mb-0'>Comment</p>,
    //   dataIndex: 'comment',
    // },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, { id, status }) => (
        <>
          {status === "PENDING"
            ? <button className="primary-btn" key={id} onClick={() => openTrack(id, status)}><i className="far fa-eye"></i> Process</button> :
            status === "APPROVED"
              ? <Tag className='py-2' color={"green"} key={status}>Workflow Completed</Tag>
              : <Tag className='py-2' color={"orange"} key={status}>{status}</Tag>
          }
        </>
      ),
    },
  ];

  const getTracks = () => {
    setTrackLoading(true)
    TrackRep.getTracks().then(response => {
      setTrackLoading(false)
      if (response) {
        if (response?.error) {
          sendNotify('error', response.message)
          setTrackList([])
        } else {
          let data = response?.data?.map((data, i) => (
            {
              key: i + 1,
              date: moment.unix(data?.submmited_date).format("DD-MM-YYYY"),
              time: moment.unix(data?.submmited_date).format('LTS'),
              client: data?.uploaded_by?.user_name,
              id: data.trackId,
              status: data.status,
              // comment: data.reason,
              actions: data
            }
          ))
          setTrackList(data)
        }
      }
    }).catch(error => {
      sendNotify('error', 'Failed to fetch Data')
      setTrackLoading(false)
    })
  }

  const searchTrack = (searchID) => {
    if (searchID) {
      setTrackLoading(true)
      TrackRep.searchTrack(searchID).then(response => {
        setTrackLoading(false)
        if (response) {
          if (response?.error) {
            sendNotify('error', response?.message)
            setTrackList([])
          } else {
            // sendNotify('success', 'Fetch track Data')
            let data = response?.data?.map((data, i) => (
              {
                key: i + 1,
                date: moment.unix(data?.submmited_date).format("DD-MM-YYYY"),
                time: moment.unix(data?.submmited_date).format('LTS'),
                client: data?.uploaded_by?.user_name,
                id: data.trackId,
                status: data.status,
                // comment: data.reason,
                actions: data
              }
            ))
            setTrackList(data)
          }
        }
      }).catch(error => {
        sendNotify('error', 'No matched track id found')
        setTrackLoading(false)
      })
    } else {
      getTracks()
    }
  }

  const onSearchClear = () => {
    setSearchID('')
    searchTrack('')
  }

  return (
    <section className='mt-md-3'>
      <div className="page-header flex-md-row flex-column">
        <h1>Documents</h1>
        <div className="custom-search mt-3 my-md-1">
          <div className='search-input-wrapper'>
            <input type="text" placeholder='Search by track code' value={searchID} onChange={(e) => setSearchID(e.target.value)} />
            <button className={`clear-btn ${searchID ? 'active' : ''}`} onClick={() => setSearchID('')}><i className="fal fa-times-circle"></i></button>
          </div>
          <button onClick={() => searchTrack(searchID)}><i className="far fa-search"></i></button>
        </div>
      </div>

      <Table columns={columns} dataSource={trackList} loading={trackLoading} pagination={{ className: 'custom-pagination' }} className='custom-table' />
    </section>
  )
}

import React, { useEffect, useState } from 'react'
import { Image } from 'antd';
import { useSelector, useDispatch } from 'react-redux'

import Logo from '../assets/img/logo-with-text.png'
import NoImg from '../assets/img/no-img.jpg'

import { sendNotify } from '../helper/notification';
import authRep from '../repository/authRep'
import { parseJwt } from '../helper/parseJwt'
import { checkLogged } from '../store/auth/authSlice'

export default function Settings() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedImg, setUploadedImg] = useState('');
  const [trackcode, setTrackcode] = useState('NLTRACK');

  let detail = useSelector((state) => state.auth.userDetails)
  const dispatch = useDispatch()

  const getFile = (e) => {
    let file = e.target.files[0]
    if (file?.size < 5242880) {
      let imgSrc = URL.createObjectURL(file);
      setUploadedFile(file)
      setUploadedImg(imgSrc)
    } else {
      sendNotify("error", "File is too big!, Upload below 5MB file.")
    }
  }

  const getTrackCode = (value) => {
    setTrackcode(value)
  }



  const verifyOtp = (modes) => {
    var payload = {
      "id": detail.userId,
      "status": modes
    }
    authRep.update2fa(payload).then(response => {
      if (response) {
        if (response.error) {
          sendNotify('error', response.error?.response?.data?.message)
        } else {
          sendNotify('success', response?.message)
          localStorage.setItem(process.env.REACT_APP_JWT_TOKEN, JSON.stringify(response?.data))
          dispatch(checkLogged())
        }
      }
    })
      .catch(error => ({ error: JSON.stringify(error) }));
  }

  return (
    <section className='mt-md-3'>
      <div className='container-fluid px-0'>
        <div className="page-header">
          <h1>Settings</h1>
        </div>
        <div className="row gy-4">
          {/* <div className="col-md-4">
            <div className="chart-box">
              <h4>Logo change:</h4>
              <div className='mt-3 text-center'>
                <Image
                  className='custom-logo'
                  src={uploadedImg || Logo}
                  fallback={NoImg}
                />
                <input type="file" className='d-none' id='file' onChange={getFile} accept='image/png, image/jpg, image/jpeg' />
                <label htmlFor="file" className='secondary-btn upload-btn mx-auto mt-4'><i className="far fa-cloud-upload-alt"></i> Update new</label>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="chart-box">
              <h4>Trackcode prefix:</h4>
              <input type="text" className='box-input mt-3 w-100' value={trackcode} onChange={(e) => getTrackCode(e.target.value)} placeholder='Enter prefix text' />
              <p className='mt-4 mb-0'><span>*Note:</span> The above text was added to the prefix of the user's trackcode.</p>
            </div>
          </div> */}
          <div className="col-md-4">
            <div className="chart-box">
              <h4>Two Factor Authentication</h4>
              {detail?.qr_code ? <>
                <div style={{ color: "#000", fontWeight: 'bold' }} className='mt-3'>QR: </div>
                <img src={`${detail && detail.qr_code}`} />
                <p><strong style={{ color: "#000" }}>Secret Key : </strong>{detail && detail?.secret_key}</p>
              </>
                : null}
              <div className='d-flex mt-4'>
                {detail && !detail?.f2a_enable ?
                  <button className='primary-btn me-4' onClick={() => verifyOtp('enable')}> Enable</button> :
                  <button className='secondary-btn me-2' onClick={() => verifyOtp('disable')}> Disable</button>}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center mt-4">
          <button className='secondary-btn me-2'><i className="far fa-undo-alt"></i> Reset <span className='d-none d-md-inline'>&nbsp;default</span></button>
          <button className='primary-btn ms-2'><i className="far fa-save"></i> Save <span className='d-none d-md-inline'>&nbsp;changes</span></button>
        </div> */}
      </div>
    </section>
  )
}
export function calculateTime(times) {
    function toSeconds(s) {
        const parts = s.split(':');
        return +parts[0] * 3600 + +parts[1] * 60 + +parts[2];
      }
      
    function secondsToHHMMSS(secs) {
        return Math.floor(secs / 3600).toString().padStart(2) + 'hr' + 
               (Math.floor(secs / 60) % 60).toString().padStart(2) + 'min ' + 
               (secs % 60).toString().padStart(2) + 'sec';
      }
      
    const totalSeconds = times.reduce(function(total, ts) {
        return total + toSeconds(ts); 
    }, 0);
      
    const result = secondsToHHMMSS(totalSeconds);

    return result
}
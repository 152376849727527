import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Logo from '../../assets/img/logo-icon.png'

import { NavLink } from "react-router-dom";

import routes from '../../helper/routes';
import Footer from './Footer'

export default function Sidebar() {
  const [sidebarCollapse, setSidebarCollapse] = useState(false)
  let details = useSelector((state) => state.auth.userDetails)

  return (
    <div className={`sidebar ${sidebarCollapse ? 'sidebar-collapse' : ''}`}>
      <img src={Logo} className='logo' alt="ICONNHRM" />
      <ul>
        {routes.filter(r => r.showInMenu).map(route => {
          if (route.permissions.find(r => r === details?.role)) {
            return (
              <li key={route.path}>
                <NavLink to={route.path}>
                  <i className={`far ${route.icon}`}></i>
                  <span>{route.name}</span>
                </NavLink>
              </li>
            )
          }
        })}
      </ul>
      <button onClick={() => setSidebarCollapse(!sidebarCollapse)} className={`toggle-btn ${sidebarCollapse ? 'rotate' : ''}`}>
        <i className="fad fa-chevron-double-left"></i>
      </button>
      <Footer />
    </div>
  )
}

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { isExpired } from "react-jwt";

//Layout
import AuthLayout from './layout/auth'
import BaseLayout from './layout/base'

import routes from './helper/routes';

import PrivateRoute from './helper/PrivateRoute';
import { sendNotify } from './helper/notification'

import { checkLogged } from './store/auth/authSlice';

export default function App() {
  const dispatch = useDispatch()

  let local = localStorage.getItem(process.env.REACT_APP_JWT_TOKEN)
  let details = local ? JSON.parse(local) : null
  let token = details?.tokenObj?.accessToken

  if (token) {
    let expired = isExpired(token);
    if (expired) {
      sendNotify('error', 'Token was expired!')
      localStorage.removeItem(process.env.REACT_APP_JWT_TOKEN)
    } else {
      dispatch(checkLogged())
    }
  }

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          {routes.filter(r => r.type === 'public').map(route => {
            return <Route element={route.component} key={route.path} path={route.path} />
          })}
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<BaseLayout />} path='/app'>
            {routes.filter(r => r.type === 'private').map(route => {
              return <Route element={route.component} key={route.path} path={route.path} />
            })}
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Skeleton, Spin } from 'antd';
import { useSelector } from 'react-redux';

import ImageZoomView from '../components/ImageZoomView';
import ManualForm from '../components/ManualForm';
import SendEmailForm from '../components/SendEmailForm';

import { sendNotify } from '../helper/notification';

import TrackRep from '../repository/TrackRep';

export default function Process() {
  let details = useSelector((state) => state.auth.userDetails)

  let { id } = useParams();
  const navigate = useNavigate();

  const [docData, setDocData] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    getImages(id)
  }, []);

  const [time, setTime] = useState({
    sec: 0,
    min: 0,
    hr: 0
  });
  const [intervalId, setIntervalId] = useState();

  const updateTimer = () => {
    setTime((prev) => {
      let newTime = { ...prev };
      // update sec and see if we need to increase min
      if (newTime.sec < 59) newTime.sec += 1;
      else {
        newTime.min += 1;
        newTime.sec = 0;
      }
      // min has increased in *newTime* by now if it was updated, see if it has crossed 59
      if (newTime.min === 60) {
        newTime.min = 0;
        newTime.hr += 1;
      }

      return newTime;
    });
  };

  const pauseOrResume = () => {
    if (!intervalId) {
      let id = setInterval(updateTimer, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId("");
    }
  };

  const getImages = (id) => {
    setImageLoading(true)
    TrackRep.getTracks(id).then(response => {
      if (response) {
        setImageLoading(false)
        if (response?.error) {
          sendNotify('error', response?.message);
        } else {
          console.log(response);
          sendNotify('success', response?.message);
          setDocData(response?.data)
          setTime(response?.data?.classification_time ?? {
            sec: 0,
            min: 0,
            hr: 0
          })
          if (response?.data?.status != "APPROVED") {
            pauseOrResume()
          }
        }
      }
    })
      .catch(error => {
        sendNotify('error', error)
      })
  }

  const backToList = () => {
    if (details?.role === 'admin') {
      navigate(`/app/tracks`)
    } else {
      navigate(`/app/track`)
    }
  }

  return (
    <section className='mt-md-3'>
      <div className='container-fluid px-0'>
        <div className="page-header flex-md-row flex-column">
          <div className="d-flex align-items-center">
            <button className='primary-btn' onClick={backToList}><i className="far fa-backward"></i> Back</button>
            <h1 className='ms-2'>Process</h1>
          </div>
          <div className="step-wrapper">
            <button className='active'>1</button>
            <button>2</button>
            <button>3</button>
          </div>
          <div>
            <p className='task-timer'>{`${time.hr < 10 ? 0 : ""}${time.hr}:${time.min < 10 ? 0 : ""}${time.min}:${time.sec < 10 ? 0 : ""}${time.sec}`}</p>
          </div>
        </div>
        <div className="page-card">
          <div className="row mt-3 mt-md-4">
            <div className="col-md-1"></div>
            <div className="col-md-5 pe-3">
              <div className='sticky-images'>
                {imageLoading ?
                  <div className="row">
                    <Skeleton.Image active className='custom-image-loader mb-3' />
                    <Skeleton.Image active className='custom-image-loader mb-3' />
                    <Skeleton.Image active className='custom-image-loader mb-3' />
                  </div>
                  :
                  <ImageZoomView
                    images={[
                      {
                        src: `data:image/jpeg;base64,${docData?.image_photo}`
                      },
                      {
                        src: `data:image/jpeg;base64,${docData?.image_front}`
                      },
                      {
                        src: `data:image/jpeg;base64,${docData?.image_back}`
                      }
                    ]}
                  />
                }
              </div>
            </div>
            <div className="col-md-5 ps-3 mb-4 mb-md-0">
              <Spin spinning={imageLoading}>
                <ManualForm trackId={id} formData={docData} taskTime={time} />
              </Spin>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
    </section>
  )
}
